import React, { useState, useEffect } from "react";
import "twin.macro"

export function Expires({ value, onChange }) {

	const [number, setNumber] = useState(parseInt(value.slice(0, -1)));
	const [interval, setInterval] = useState(value.charAt(value.length - 1));

	useEffect(() => {
		onChange(`${number}${interval}`);
	}, [number, interval]);

	return <div tw="grid grid-cols-4 gap-4">
		<div tw="col-span-2">
			<input
				tw="mb-0!"
				type="number"
				min="1"
				onChange={e => setNumber(e.target.value)}
				value={number}
			></input>
		</div>
		<div tw="col-span-2">
			<select tw="mb-0!" value={interval} onChange={e => setInterval(e.target.value)}>
				<option value="h">Hours</option>
				<option value="d">Days</option>
				<option value="w">Weeks</option>
				<option value="M">Months</option>
				<option value="y">Years</option>
			</select>
		</div>
	</div>;

}
