import React, { useEffect, useState, useContext } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Uploader } from '../components/uploader/uploader.js'
import Wallpaper  from "../components/wallpaper"
import tw, {css} from 'twin.macro'
import { useUser } from '../hooks/useUser'
import { CognitoUser, AnonymousUser, User } from '../classes/user'
import { navigate } from "gatsby"
import About from '../components/about'
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import UiStoreContext from "../contexts/uiStoreContext"
import {observer } from 'mobx-react-lite'

const IndexPage = observer((props) => {

	const uiStore = useContext(UiStoreContext)

	const locationHook = useLocation()
	const searchParams = parse(locationHook.search)

	const adId = searchParams.ad || null

	const { user } = useUser()
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (typeof user !== 'undefined') {
			if (user instanceof CognitoUser) {
				navigate('/me')
			}
			else {
				setShow(true)
			}
		}
	}, [user])

	return  <>
		<SEO />
		{ show ? <>
				{/* <div tw="bg-viking w-14 absolute flex flex-col items-center justify-center border-2 border-purple" style={{height:`${height}px`,zIndex:10000}}>{height}</div> */}
				<Wallpaper/>
				<Layout defaultTheme={false} footer={false} fullWidth={true}>
					 <div tw="sm:min-h-screen flex flex-col justify-center -mt-14 pt-14">
						{<Uploader adId={adId}/>}
					</div>
					<About/>
				</Layout>
			</> :
			null
		}
	</>
})

export default IndexPage
