import "react"
import tw, {css} from "twin.macro"
import Button from "../button"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const UploadProgress = ({activeProgressPercent, onCancelClick}) => {

	return !isNaN(activeProgressPercent) && isFinite(activeProgressPercent) ? <div tw="my-2 text-sm text-gray-700 flex">
		<div tw="flex-1 inline-block bg-gray-300 rounded">
			<div tw="h-full bg-blue rounded inline-block flex items-center" css={{ width: `${activeProgressPercent}%` }}>
				<span tw="p-1 text-white">{`${activeProgressPercent}%`}</span>
			</div>
		</div>
		<Button skinny icon={faTimes} tw="ml-2" onClick={onCancelClick}>Cancel</Button> 
	</div>: null

}

export default UploadProgress