
import React, { useState, useEffect, useRef, useCallback, useContext } from "react"
import { Link, navigate } from "gatsby"

import {css} from "twin.macro"
import Button from "../button"
import { useApi } from '../../hooks/useApi'
import { useUser } from '../../hooks/useUser'
import Icon from '../icon'
import byteSize from 'byte-size'
import QRCode from 'qrcode.react';
import { useUploader, UploaderProvider } from "../../hooks/useUploader"
import {
	faTimes,
	faChevronDown,
	faChevronUp,
	faChevronRight,
	faChevronLeft,
	faRefresh
} from '@fortawesome/free-solid-svg-icons'
import { TransferDetails } from "./transferDetails"
import LinkButton from "../linkButton"
import _get from "lodash.get"
import UiStoreContext from "../../contexts/uiStoreContext"
import { Floater } from "../floater"
import { FloaterErrorModal } from "../floaterErrorModal"
import { FloaterModal } from "../floaterModal"
import useResizeObserver from "use-resize-observer"
import { TimeoutComponent } from "../timeoutComponent"
import MobileAd from "../mobileAd"
import { TooltipWrapper, TooltipGroup } from "../tooltip"
import DownloadLink from "./downloadLink"
import UploadProgress from "./uploadProgress"
import { FileView } from "./fileView"
import { UploadControls } from "./uploadControls"

const RestartTransferModal = ({onStartNewTransfer, onClose}) => <FloaterModal
	header="Start New Transfer?"
>
	<div tw="mb-4">The current transfer is no longer valid. Would you like to start a new one?</div>
	<Button tw="mb-4" dark onClick={onStartNewTransfer}>Start a New Transfer</Button>
	<Button onClick={onClose}>Cancel</Button>
</FloaterModal>

const PerTransferLimitError = ({onClose}) => <FloaterErrorModal 
	title={"2 GB Transfer Limit"}
	details={
		<>
			<p>The selected file(s) would exceed the 2 GB per transfer limit.</p>
			<p tw="mt-4">You can transfer larger files with a <a href="/plans" target="top">paid plan</a>.</p>
		</>
	}
	onClose={onClose}
/>

const PerHourLimitError = ({retryAfter, onClose}) => {
	const minutes = Math.round(retryAfter / 1000 / 60)
	return <FloaterErrorModal
		title={"4 GB Per Hour Limit"}
		details={
			<>
				<p>You have reached the 4 GB per hour upload limit. Please retry your upload in {minutes} minutes.</p>
				<p tw="mt-4">You can bypass this limit with a <a href="/plans" target="top">paid plan</a>.</p>
			</>
		}
		onClose={onClose}
	/>
}

export const UploaderWidget = (props) => {

	const TRANSFER_LIMIT_BYTES = 2 * 1000 * 1000 * 1000
	const TRANSFER_LIMIT_SIZE = byteSize(TRANSFER_LIMIT_BYTES, {precision: 0})

	const { callApi } = useApi()
	const { clearAnonymousUserToken, user } = useUser()

	const [folderList, setFolderList] = useState([])
	const [transfer, setTransfer] = useState()
	const [complete, setComplete] = useState(false)
	const [expires, setExpires] = useState('2w')
	const [error, setError] = useState()
	const [activeProgressPercent, setActiveProgressPercent] = useState();
	const [modal, setModal] = useState()
	const [restartFiles, setRestartFiles] = useState()
	const [viewFiles, setViewFiles] = useState()

	const uiStore = useContext(UiStoreContext)
	const floaterRef = useRef()	

	const ERROR = {
		PER_TRANSFER_LIMIT : 1,
		PER_HOUR_LIMIT : 2,
		UNKNOWN : 0
	}

	const { 
		files, 
		stats,
		setOptions, 
		addFiles, 
		cancelFile,
		cancelAll,
		reset: resetUploader,
	} = useUploader()

	const onComplete = useCallback((file, responseJson, response) => {
		const deferred = firstFileDeferred.current
		if (deferred && deferred.file.id === file.id) {
			handleTransferDetailsChange(responseJson)
			deferred.resolve(file)
		}
		uiStore.setAnonymousUploadComplete(true)
	}, [])

	const onAllComplete = useCallback((statsSnapshot) => {
		setComplete(true)
		const params = {
			"file_count": statsSnapshot.active.fileCount,
			"elapsed_ms": statsSnapshot.active.elapsedMilliseconds,
			"bytes": statsSnapshot.active.totalBytes,
			"total_file_count": statsSnapshot.all.fileCount,
			"total_elapsed_ms": statsSnapshot.all.elapsedMilliseconds,
			"total_bytes": statsSnapshot.all.totalBytes
		}
		// console.log("transfer_complete", params);
		typeof window !== "undefined" && window.gtag && window.gtag("event", "transfer_complete", params)
	},[setComplete, stats])

	const folderPromise = useRef()
	const firstFileDeferred = useRef()

	const onUpload = useCallback((file) => {
	
		const getTransferFolder = async (name) => {
			if (!folderPromise.current) {
				folderPromise.current = new Promise( async (resolve, reject) => {
					if (firstFileDeferred.current) {
						const firstFile = await firstFileDeferred.current.promise
						if (firstFile) {
							// first copy the file
							const fileCopy = await callApi({
								method: 'post',
								namespace: '',
								payload: {
									copyKey: firstFile.response.data.key
								}
							})
							// convert the original file to a folder
							const folder = await callApi({
								method: 'put',
								namespace: firstFile.response.data.key,
								payload: {
									nodeType: 'folder',
									title: firstFile.response.data.title,
									autoDelete: true,
									expires: '2w',
									maxDownloads: 1
								}
							})
							// move the copied file into the folder
							await callApi({
								method: 'patch',
								namespace: fileCopy.data.key,
								payload: {
									path: firstFile.response.data.name
								} 
							})
							handleTransferDetailsChange(folder.data)
							resolve(folder.data)
						}
					}
					else {
						callApi({
							method: 'post',
							namespace: `~`,
							payload: {
								name: name,
								title: name,
								nodeType: 'folder',
								autoDelete: true,
								expires: '2w',
								maxDownloads: 1
							}
						})
						.then( result => {
							handleTransferDetailsChange(result.data)
							resolve(result.data)
						})
					}
				})
			}
			return folderPromise.current
		}

		const getUploadOptions = async () => {

			let isTransferValid = true
			let transferNode 
			
			if (folderPromise.current) {
				transferNode = await folderPromise.current
			}
			else if (firstFileDeferred.current) {
				const firstFile = await firstFileDeferred.current.promise
				transferNode = firstFile.response.data
			}

			if (transferNode) {
				const transferMetadata = await new Promise((resolve, reject) => {
					callApi({
						method: 'get',
						namespace: `${transferNode.key}/metadata`
					})
					.then(resolve)
					.catch(err => {
						resolve(null)
					})
				})
				isTransferValid = Boolean(transferMetadata)
			}

			if (!isTransferValid) {
				const restartFiles = files.filter(f => f.status !== 'complete')
				setRestartFiles(restartFiles)
				setModal('restart')
				return false
			}

			if (files.length === 1) {
				let deferred
				const promise = new Promise((resolve, reject) => {
					deferred = {resolve, reject}
				})
				deferred.promise = promise
				deferred.file = file
				firstFileDeferred.current = deferred
				return { endpoint: `?title=${encodeURIComponent(file.name)}` }
			}
			else if (files.length > 1) {
				const transfer = await getTransferFolder(files[0].folder ? files[0].folder.name : files[0].name)
				const folderEndpoint = `~/${encodeURIComponent(transfer.name)}`
				return file.folder ?
					{ endpoint: `${folderEndpoint}/${file.path}`} :
				 	{ endpoint: folderEndpoint }
			}
			else {
				throw('Error calculating transfer endpoint. files.length is <= 0.')
			}
		}

		return getUploadOptions()
	
	}, [folderPromise, firstFileDeferred, files])

	const onCancel = useCallback((file) => {
		// if the file was created successfully and it's not in a folder
		// then delete it from the server.
		// TODO: it feels like this logic belongs in the provider somehow
		if(file.response && !file.folder) {
			callApi({
				method: 'delete',
				namespace: file.response.data.key
			})
			.catch(err => {
				console.log(err)
			})
		}

	},[callApi])

	const onError = useCallback((file, error) => {
		
		console.log(error)

		// ignore upload canceled error
		if (error.message === 'canceled') {
			return
		}

		let errorData = {
			code: ERROR.UNKNOWN
		} 
		
		try {
			if (_get(error,"response.data.code") === 'FILE_UPLOAD_LIMIT') {
				const retryAfter = _get(error,"response.data.retryAfter")
				errorData = {
					code: ERROR.PER_HOUR_LIMIT,
					params: {
						retryAfter
					}
				}
			}
			else if (_get(error,"response.data.code") === 'FILE_UPLOAD_LIMIT') {
				errorData.code = ERROR.PER_TRANSFER_LIMIT
			}
			else {
				console.log("unknown err:" + error)
			}
		}
		finally {
			setError(errorData);
		}

	},[])

	useEffect(() => {
		uiStore.setDisplayAds(true)
	})
	
	useEffect(() => {
		
		// if (files.length) {
		// 	uiStore.setDisplayAds(true)
		// }

		// remove error files from the list	
		const errorFiles = files.filter(f => f.error)
		
		for(const file of errorFiles) {
			if (file.folder) {
				handleRemoveFolder(file.folder)
			}
			else {
				cancelFile(file)
			}	
		}

	}, [files])

	useEffect(() => {
		setOptions({
			endpoint : '',
			onUpload,
			onCancel,
			onError,
			onComplete,
			onAllComplete
		})
	}, [onUpload, onCancel, onError, onComplete, onAllComplete])
	
	const handleSelectFolder = async (event) => {

		const files = Array.from(event.target.files)

		const relativePath = files[0].webkitRelativePath
		let folderName = relativePath && relativePath.split('/')[0].trim()

		// folder name must be unique
		if (folderList.find(f => f.name === folderName)) {
			const regex = new RegExp("^" + folderName + "(?: \\((\\d+)\\))?$")
			let maxExistingIncrement = 0
			folderList.forEach(f => { 
				const match = f.name.match(regex) 
				if (match && match[1]) {
					const inc = parseInt(match[1])
					if (inc > maxExistingIncrement) {
						maxExistingIncrement = inc
					}
				}
			})
			folderName = `${folderName} (${maxExistingIncrement+1})`
		}

		const folder = {
			name: folderName,
			key: folderName,
			fileCount: files.length,
			size: 0,
			uploaded: 0
		}

		for(const file of files) {
			folder.size += file.size 
			file.folder = folder 
			file.path = [folderName, ...file.webkitRelativePath.split('/').slice(1, -1)]
				.map(f => encodeURIComponent(f))
				.join('/')
		}

		if (stats.all.totalBytes + folder.size > TRANSFER_LIMIT_BYTES) {
			setError({
				code: ERROR.PER_TRANSFER_LIMIT
			})
			return
		}

		setComplete(false)

		setFolderList(prevFolderList => [...prevFolderList, folder])
		
		addFiles(files)

	}

	const handleSelectFiles = async (event) => {
		
		const files = Array.from(event.target.files)

		const totalSizeOfNewFiles = files.reduce( (acc, item) => acc + item.size, 0)

		if (stats.all.totalBytes + totalSizeOfNewFiles > TRANSFER_LIMIT_BYTES) {
			setError({ code: ERROR.PER_TRANSFER_LIMIT})
			return
		}

		setComplete(false)
		addFiles(files)

	}

	const handleRemoveFile = (fileToRemove) => {
		cancelFile(fileToRemove)
	}

	const handleRemoveFolder = (folderToRemove) => {

		const folderFiles = files.filter(f => f.folder && f.folder.name === folderToRemove.name)

		for (const file of folderFiles) {
			cancelFile(file)
		}

		if (transfer) {
			callApi({
				method: 'delete',
				namespace: `~/${transfer.name}/${encodeURIComponent(folderToRemove.name)}`
			})
			.catch(err => {
				console.log(err)
			})
		}

		setFolderList(prevFolderList => prevFolderList.filter(f => f.name !== folderToRemove.name))

	}

	const handleTransferDetailsChange = (transferData, expires) => {
		if (expires) {
			setExpires(expires)
		}
		setTransfer(transferData)
	}
	
	const handleStartNewTransfer = () => {
		setModal(null)
		reset()
		addFiles(restartFiles)
	}

	useEffect(() => {
		setActiveProgressPercent(Math.min(Math.round(stats.active.uploadedBytes * 100 / stats.active.totalBytes), 100.0))
	}, [stats])
	
	const reset = useCallback(() => {
		folderPromise.current = null
		firstFileDeferred.current = null
		clearAnonymousUserToken()
		setTransfer(null)
		setComplete(false)
		setFolderList([])
		resetUploader()
		setModal(null)
		uiStore.setAnonymousUploadComplete(false)
	}, [resetUploader])

	// if user deletes all files then completely reset the transfer
	useEffect(() => {
		if (transfer && !files.length) {
			reset()
		}
	}, [files, transfer, reset, callApi])

	const link = `${process.env.GATSBY_API_URL}/${transfer && transfer.key}`
	const totalSize = byteSize(stats.all.totalBytes)

	const renderError = (onClose) => {
		switch(error.code) {
			case ERROR.PER_HOUR_LIMIT:
				return <PerHourLimitError {...error.params} onClose={onClose}/>
			case ERROR.PER_TRANSFER_LIMIT:
				return <PerTransferLimitError onClose={onClose}/>
			default:
				return <FloaterErrorModal 
					title="Error" 
					details={<p>An unknown error occurred. Please try again.</p>}
					onClose={onClose}	
				/>
		}
	}

	const statsDisplay = <div tw="text-sm sm:text-xs text-gray-500 italic text-left mt-3 flex">
		<div tw="flex-1">{files.length} file{files.length > 1 ? 's' : ''}</div>
		<div tw="flex-1 text-right">{totalSize.value} {totalSize.unit.toUpperCase()} of {TRANSFER_LIMIT_SIZE.value} {TRANSFER_LIMIT_SIZE.unit.toUpperCase()}</div>
	</div>

	const uploading = (Boolean(files.length) && !complete)

	const modals = <>
		{(modal === 'viewFiles' || uploading) && <FloaterModal
			header={uploading ? "Uploading..." : "View Files"}
			closable={!uploading}
			onClose={evt => { setModal(null) }}
		>
			{
				// !(Boolean(files.length) && !complete) && 
				// <div tw="flex items-center justify-end pb-2">
				// 	<div tw="flex-grow text-2xl sm:text-xl text-blue">View Files</div>
				// 	<Icon tw="h-8 cursor-pointer text-blue" icon={faTimes} onClick={evt => {setModal(null)}}/>
				// </div>
			}
			{Boolean(files.length) && !complete && <UploadProgress
				activeProgressPercent={activeProgressPercent}
				onCancelClick={cancelAll}
			/>}
			<div tw="flex-grow sm:overflow-y-scroll sm:h-0 sm:min-h-0 sm:-ml-2 sm:-mr-4">
				<FileView
					folderList={folderList}
					files={files}
					onRemoveFolder={handleRemoveFolder}
					onRemoveFile={handleRemoveFile}
				/>
			</div>
			{statsDisplay}
		</FloaterModal>
	}
		{modal === 'qr' && <FloaterModal
			header="QR Code"
			onClose={() => { setModal(null) }}
		>
			<div tw="flex-grow w-full flex justify-center items-center">
				<QRCode
					tw="h-60 w-60"
					css={{
					}}
					renderAs="svg"
					value={link}
				/>
			</div>
		</FloaterModal>}
		{modal === 'restart' && <RestartTransferModal
			onStartNewTransfer={handleStartNewTransfer}
			onClose={() => { setModal(null) }}
		/>
		}
		{error && renderError(() => { setError(null) })}
	</>
	return (<>
		<Floater {...props}>
			<TooltipGroup>
			{ user && user.jwtPayload && <TimeoutComponent
				callback={reset}
				delay={user.jwtPayload.exp * 1000 - (new Date()).getTime()}
			/>}
			{!modal && !uploading && !error && 
				<div 
					ref={floaterRef}
					tw="flex-grow z-2000 top-14 sm:top-0 w-full bg-white sm:relative sm:rounded-lg py-4 flex flex-col"
				>
					{!Boolean(files.length) && 
						<div tw="flex-grow text-center mx-4 flex flex-col">
							<p tw="font-medium text-blue text-2xl sm:text-3xl sm:text-3xl mb-6 sm:my-6 sm:mt-0">Super simple file sharing!</p>
							<p tw="text-lg">Upload as many files as you like up to <span tw="whitespace-nowrap">2 GB</span> and get a link to share.</p>
							<div>
							<LinkButton tw="mt-4 mb-8" onClick={() => navigate('/about')}>Learn More</LinkButton>
							</div>
							<div tw="flex-grow flex flex-col items-center">
							<UploadControls
								files={files}
								onSelectFiles={handleSelectFiles}
								onSelectFolder={handleSelectFolder}
							/>
							</div>
						</div>
					}
					{files && Boolean(files.length) && 
					<div tw="flex-grow flex flex-col mx-4 bg-opacity-20 sm:rounded-lg">
						{(complete && !viewFiles) &&
							<div tw="flex-grow flex flex-col">
								<div tw="font-medium text-blue text-2xl block w-full my-2 text-center">Your {files.length > 1 ? 'files are' : 'file is'} ready to share!</div>
								<div tw="flex-grow sm:h-0 sm:min-h-0 pt-2 pr-4 -mr-4 sm:overflow-y-scroll">
									<div tw="flex flex-col items-start justify-start">
										<div tw="w-full flex items-stretch">
											<div tw="flex-grow mr-4 flex flex-col">
												<div tw="text-sm sm:text-base flex-grow flex mx-1">Copy the download link below or scan the QR code.</div>
												<DownloadLink link={link} tooltipTarget={floaterRef} />
											</div>
											<div tw="flex flex-col items-center justify-start cursor-pointer" onClick={() => {setModal('qr')}}>
												<TooltipWrapper 
													tip={<div>Click to enlarge</div>}
													options={{
														id:"qrCode",
														middlewares: [],
														breakpoints: {
															sm: {
																placement: "right",
																containerElement: floaterRef,
																interactions: ['hover'],
															}
														}
													}}
												>
													<QRCode 
														size={100}
														renderAs="canvas" 
														value={link}
													/>
												</TooltipWrapper>
											</div>
										</div>
									</div>
									<div tw="flex-grow">
										<div tw="flex mt-4 items-center text-sm border-t py-2">
											<div tw="flex-grow grid grid-cols-3">
												<div tw="self-center justify-self-center">{files.length} file{files.length > 1 ? 's' : ''} &ndash; {totalSize.value} {totalSize.unit.toUpperCase()}</div>
												<Button tw="ring-0" skinny icon={faChevronRight} onClick={evt => {setModal('viewFiles')}}>View Files</Button>
												<Button tw="ring-0" skinny icon={faRefresh} onClick={reset}>Start Over</Button>
											</div>
										</div>
										<div tw="flex sm:hidden border-t py-2">
											<UploadControls
												files={files}
												onSelectFiles={handleSelectFiles}
												onSelectFolder={handleSelectFolder}
												stack={false}
											/>
										</div>
										<TransferDetails
											transfer={transfer}
											expires={expires}
											onTransferChange={handleTransferDetailsChange}
											errorAnchorElement={floaterRef}
										/>
									</div>
								</div>
								<div tw="hidden sm:flex mt-4">
									<UploadControls
										files={files}
										onSelectFiles={handleSelectFiles}
										onSelectFolder={handleSelectFolder}
										stack={false}
									/>
								</div>
							</div>
						}
					</div>}
				</div>
			}
			</TooltipGroup>
			{(modal || error || uploading) && <div tw="hidden sm:flex sm:h-full">
				{modals}
			</div>}
		</Floater>
		<div tw="sm:hidden">
			{modals}
		</div>
		
		</>
	);

}

export const Uploader = (props) => { return  <UploaderProvider><UploaderWidget {...props}/></UploaderProvider> }
