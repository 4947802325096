import React from "react";
import { UploadFile } from "../uploadFile";
import { UploadFolder } from "../uploadFolder";

export const FileView = ({ folderList, files, onRemoveFolder, onRemoveFile }) => (
	<>
		{folderList.map(folder => <UploadFolder
			folder={folder}
			key={folder.name}
			remove={onRemoveFolder}
		></UploadFolder>)}
		{files
			.filter(f => !f.folder)
			.map(file => <UploadFile
				key={file.id}
				file={file}
				remove={onRemoveFile}
			></UploadFile>)}
	</>
);
