import React, { useEffect } from "react"
import Button from './button'
import tw, {css} from 'twin.macro'
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import Icon from './icon'

const windowGlobal = typeof window !== 'undefined' && window

export const FloaterModal = ({ header, closable=true, content, buttons, buttonText, onClose, children }) => {

	useEffect(() => {
		if (windowGlobal) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, [])

	return <div tw="absolute sm:relative p-4 flex flex-col w-full min-h-screen sm:min-h-0 sm:h-full top-0 sm:mt-0 z-40 bg-white sm:rounded-lg" >
		<div tw="flex items-center justify-end pb-4">
			<div tw="flex-grow text-2xl sm:text-xl text-blue flex items-center">{header}</div>
			{closable && <Icon tw="h-8 cursor-pointer text-blue" icon={faTimes} onClick={onClose} /> }
		</div>
		<div tw="flex-grow text-left text-xl flex flex-col">
			{children}
		</div>
	</div>;
};
