import React, { useMemo } from "react";
import { useApi } from '../../hooks/useApi';
import { useForm, Controller } from "react-hook-form";
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { Expires } from "./expires";
import tw, { css } from "twin.macro"
import { TooltipWrapper } from "../tooltip";

const Item = ({ label, htmlFor, children, errors, errorAnchorElement }) => {

	const item = <div tw="border-t flex flex-col sm:flex-row items-start sm:items-center py-2">
		<label tw="w-24! p-0! mb-1 sm:mb-0! flex-shrink-0" htmlFor={htmlFor}>{label}</label>
		<div tw="flex-grow text-base w-full">{children}</div>
	</div>

	return <TooltipWrapper 
		tip={<div>{errors && errors[htmlFor] && errors[htmlFor].message}</div>}
		tipStyle={tw`bg-red`}
		options={{
			id:`error_${htmlFor}`,
			placement:"top",
			breakpoints: {
				sm: {
					placement: "right",
					containerElement: errorAnchorElement,
				}
			}
		}}
		isOpen={Boolean(errors && errors[htmlFor])}
	>{item}</TooltipWrapper>

}


export function TransferDetails({ transfer, expires, onTransferChange, errorAnchorElement }) {

	const { register, control, handleSubmit, setValue, setError, errors } = useForm({
		defaultValues: {
			title: transfer && transfer.title,
			description: transfer && transfer.description,
			expires: expires
		}
	});

	const { callApi } = useApi();

	const debouncedChangeHandler = useMemo((event) => {
		return debounce(() => {
			handleSubmit(async (data) => {

				if (!transfer) {
					return;
				}

				const payload = data;

				if (transfer.folder) {
					payload.name = data.title || transfer.key ;
				}

				const newTransfer = await callApi({
					method: 'patch',
					namespace: transfer.key,
					payload
				});

				onTransferChange(newTransfer.data, data.expires);

			})(event);
		}, 300);
	}, [handleSubmit, onTransferChange]);

	return <div tw="text-left">
		<form onChange={debouncedChangeHandler}>
			<div tw="text-sm">
				<Item
					label="Title"
					htmlFor="title"
				>
					<input
						tw="h-8! inline! mb-0!"
						type="text"
						name="title"
						placeholder="Enter a title for this transfer"
						maxLength={100}
						ref={register()}
					/>
				</Item>
				<Item
					label="Description"
					htmlFor="description"
				>
					<textarea
						tw="mb-0!"
						rows="3"
						name="description"
						placeholder="Enter a description or message"
						maxLength={280}
						ref={register()} 
					/>
				</Item>
				<Item
					label="Expires"
					htmlFor="expires"
					errors={errors}
					errorAnchorElement={errorAnchorElement}
				>
					<div tw="flex flex-col">
						<Controller
							name="expires"
							control={control}
							rules={{
								validate: (value) => {
									const number = parseInt(value.slice(0, -1));
									if (isNaN(number) || number < 1) {
										return "Number must be >= 1"
									}
									const interval = value.charAt(value.length - 1);
									const now = dayjs();
									if (now.add(number, interval).isAfter(now.add(1, 'y'))) {
										return "Must be less than one year"
									}
								}
							}}
							render={props => <Expires
								value={props.value}
								onChange={(value) => props.onChange(value)} 
							/>}
						/>
					</div>
				</Item>
			</div>
		</form>
	</div>;

}
