import React, { useState } from "react";
import tw from "twin.macro";
import FileInputButton from '../fileInputButton';
import FolderInput from "../folderInput";
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import mq from '../../styles/media-queries';

export const UploadControls = ({ files, onSelectFiles, onSelectFolder, stack=true }) => {

	const [addFilesHover, setAddFilesHover] = useState(false);

	return <div
		tw="flex-grow flex items-center"
		css={[
			stack && tw`flex-col justify-start w-full`
		]}
		onMouseEnter={() => { setAddFilesHover(true); }}
		onMouseLeave={() => { setAddFilesHover(false); }}
	>
		<FileInputButton
			id="upload-button"
			icon={faUpload}
			tw="w-full mb-2"
			css={!stack && tw`flex-grow w-auto! mb-0`}
			dark
			handleSelectFiles={onSelectFiles}
		>{files && files.length ? 'Add More Files' : 'Upload Files'}</FileInputButton>
		{<div tw="hidden sm:block sm:ml-4 text-center">
			or <FolderInput
				tw="text-blue"
				id="select-folder"
				handleSelectFolder={onSelectFolder}
			>Upload a folder</FolderInput>
		</div>}
	</div>;
};
