import * as React from "react";
import { mergeRefs } from "react-merge-refs";
import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	arrow,
	useHover,
	useClick,
	useFocus,
	useDismiss,
	useRole,
	useInteractions,
	FloatingPortal,
	FloatingContext
} from "@floating-ui/react-dom-interactions";
import type { Placement } from "@floating-ui/react-dom-interactions";
import tw from "twin.macro"
import useTimeout from "../hooks/useTimeout";
import { useEffect, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions"
import breakpoints from "../styles/breakpoints"


export function useTooltipState({
	id=null,
	initialOpen = false,
	placement = "top",
	autoCloseDelay = null,
	containerElement = null,
	interactions = []
}: {
	id? :string | null;
	initialOpen?: boolean;
	placement?: Placement;
	autoCloseDelay? :number | null;
	containerElement?: HTMLElement | null;
	interactions?: Array<string>;
} = {}) {
	const [open, setOpen] = React.useState(initialOpen);

	const arrowRef = React.useRef(null);

	const container = (containerElement) => ({
		name: 'container',
		options: containerElement,
		fn: ({ x, y }) => {
			if (containerElement && containerElement.current) {
				const box = containerElement.current.getBoundingClientRect()
				return {
					x: box.right,
					y: y
				}
			}
			else {
				return {
					x: x,
					y: y
				}
			}
		},
	});

	const {setCurrentId} = useTooltipGroupContext();

	const data = useFloating({
		placement,
		open,
		onOpenChange(open) {
			setOpen(open)
			if (open) {
				setCurrentId(id)
			}
		},
		whileElementsMounted: autoUpdate,
		middleware: [container(containerElement),offset(16), flip(), shift(), arrow({element: arrowRef})]
	});

	const context = data.context;

	useTooltipGroup(context, {id})

	const click = useClick(context, {enabled: interactions.indexOf('click') !== -1})
	const focus = useFocus(context, {enabled: interactions.indexOf('focus') !== -1})
	const hover = useHover(context, { move: false,  enabled: interactions.indexOf('hover') !== -1} )
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	const interactionsPropGetters = useInteractions([click, focus, hover, dismiss, role]);

	const ret = React.useMemo(
		() => ({
			arrowRef,
			autoCloseDelay,
			open,
			setOpen,
			...interactionsPropGetters,
			...data
		}),
		[open, setOpen, interactionsPropGetters, data, arrowRef, autoCloseDelay]
	);

	return ret;
}

type TooltipState = ReturnType<typeof useTooltipState>;

export const TooltipAnchor = React.forwardRef<
	HTMLElement,
	React.HTMLProps<HTMLElement> & {
		state: TooltipState;
		asChild?: boolean;
	}
>(function TooltipAnchor(
	{ children, state, asChild = false, ...props },
	propRef
) {
	const childrenRef = (children as any).ref;
	const ref = React.useMemo(
		() => mergeRefs([state.reference, propRef, childrenRef]),
		[state.reference, propRef, childrenRef]
	);

	// `asChild` allows the user to pass any element as the anchor
	if (asChild && React.isValidElement(children)) {
		return React.cloneElement(
			children,
			state.getReferenceProps({ ref, ...props, ...children.props })
		);
	}

	return (
		<div ref= { ref } {...state.getReferenceProps(props)}>
			{ children }
		</div>
	);
});

export const AutoClose = ({delay, setOpen}) => {
	useTimeout(() => {
		setOpen(false)
	}, delay)
	return null
}

export const Tooltip = React.forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & { state: TooltipState }
>(function Tooltip({ state, ...props }, propRef) {

	const ref = React.useMemo(() => mergeRefs([state.floating, propRef]), [
		state.floating,
		propRef
	]);

	const {x: arrowX, y: arrowY} = (state.middlewareData && state.middlewareData.arrow) ? 
		state.middlewareData.arrow : 
		{x:null, y:null};

	const staticSide = {
		top: 'bottom',
		right: 'left',
		bottom: 'top',
		left: 'right',
	}[state.placement.split('-')[0]];

	return (
		<FloatingPortal>
		{
			state.open && (
				<>
					{state.autoCloseDelay && <AutoClose delay={state.autoCloseDelay} setOpen={state.setOpen} />}
					<div
						ref={ ref }
						css = {{
							zIndex: 3000,
							position: state.strategy,
							top: state.y ?? 0,
							left: state.x ?? 0,
							visibility: state.x == null ? "hidden" : "visible",
							...props.style
						}}
						{...state.getFloatingProps(props) }
					>
						<div ref={state.arrowRef} css={{
							background: 'inherit',
							zIndex: 3000,
							position: "absolute",
							width: "16px",
							height: "16px",
							left: arrowX ?? '',
							top: arrowY ?? '',
							[staticSide] : '-8px',
							transform: "rotate(45deg)"
						}}/>
						{props.children}
					</div>
				</>
			)}
		</FloatingPortal>
	);
});

const BREAKPOINTS = breakpoints.reduce( (acc, cur) => ({...acc, ...cur}), {})

export const TooltipWrapper = ({tip, tipStyle, options, isOpen, onClose, ...props}) => {

	const { width } = useWindowDimensions()
	const [bpOptions, setBpOptions] = useState()	

	useEffect(() => {

		const {breakpoints:optionsBreakpoints, ...optionsRest} = options

		const finalOptions = Object.assign({}, optionsRest)

		if (optionsBreakpoints) {
			
			let bpToApply: string | null = null

			for(let i=0; i<breakpoints.length; i++) {
				let bpName = Object.keys(breakpoints[i])[0]
				let bpValue = Object.values(breakpoints[i])[0]
				if (width >= bpValue && optionsBreakpoints.hasOwnProperty(bpName)) {
					bpToApply = bpName
				}
			}

			if (bpToApply) {
				Object.assign(finalOptions, optionsBreakpoints[bpToApply])
			}
			
		}

		setBpOptions(finalOptions)
		

	}, [width])

	const state = useTooltipState(bpOptions)

	useEffect(() => {
		state.setOpen(isOpen)
	}, [isOpen])

	useEffect(() => {
		if (!state.open && onClose) {
			onClose()
		}
	}, [state.open])

	return <>
		<Tooltip state={state} css={[
			tw`p-4 bg-purple text-white`,
			tipStyle
		]}>
			{tip}
		</Tooltip>
		<TooltipAnchor state={state}>{props.children}</TooltipAnchor>
	</>

}

interface GroupState {
	currentId: any;
}

interface GroupContext extends GroupState {
	setCurrentId: React.Dispatch<React.SetStateAction<any>>;
	setState: React.Dispatch<React.SetStateAction<GroupState>>;
}

const TooltipGroupContext = React.createContext<
	GroupState & {
		setCurrentId: (currentId: any) => void;
		setState: React.Dispatch<React.SetStateAction<GroupState>>;
	}
>({
	currentId: null,
	setCurrentId: () => { },
	setState: () => { },
});

export const useTooltipGroupContext = (): GroupContext =>
	React.useContext(TooltipGroupContext);

/**
 * Provides context for a group of tooltips that should have
 * only one open at a time.
  */
export const TooltipGroup = ({
	children,
}: {
	children?: React.ReactNode;
}): JSX.Element => {
	const [state, setState] = React.useState<GroupState>({
		currentId: null,
	});

	const setCurrentId = React.useCallback((currentId: any) => {
		setState((state) => ({ ...state, currentId }));
	}, []);

	return (
		<TooltipGroupContext.Provider
			value={React.useMemo(
				() => ({ ...state, setState, setCurrentId }),
				[state, setState, setCurrentId]
			)}
		>
			{children}
		</TooltipGroupContext.Provider>
	);
};

interface UseGroupOptions {
	id: any;
}

export const useTooltipGroup = (
	{ open, onOpenChange }: FloatingContext,
	{ id }: UseGroupOptions
) => {
	
	const { currentId, setState } = useTooltipGroupContext();

	React.useEffect(() => {
		if (currentId) {
			setState((state) => ({
				...state
			}));

			if (currentId !== id) {
				onOpenChange(false);
			}
		}
	}, [id, onOpenChange, setState, currentId]);

	React.useEffect(() => {
		if (!open && currentId === id) {
			onOpenChange(false);
			setState((state) => ({ ...state }));
		}
	}, [open, setState, currentId, id, onOpenChange]);

};