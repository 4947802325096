import React, { Component, useState } from 'react'
import 'twin.macro'
import Button from './button'

const FileInputButton = ({id, ...props}) => {

	const [key, setKey] = useState()

	const handleChange = (event) => {
		props.handleSelectFiles(event)
		setKey(Date.now())
	}

	return <>
		<Button as="label" htmlFor={id} {...props}>{props.children}</Button>
		<input id={id} type="file" tw="hidden!" multiple key={key} onChange={handleChange}/>
	</>

}

export default FileInputButton

