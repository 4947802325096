import React from "react";
import Icon from './icon';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FloaterModal } from "./floaterModal";
import "twin.macro"

export const FloaterErrorModal = ({ title, details, onClose }) => <FloaterModal
	header={<><Icon icon={faExclamationCircle} tw="h-6 inline mr-2" /> {title}</>}
	onClose={onClose}
>
	<div tw="text-base p-2 text-left">{details}</div>
</FloaterModal>
