import React, { Component, useState } from 'react'
import 'twin.macro'
import LinkButton from './linkButton'

const FolderInput = ({id, ...props}) => {

	const [key, setKey] = useState()

	const handleChange = (event) => {
		props.handleSelectFolder(event)
		setKey(Date.now())
	}

	return <>
		<LinkButton as="label" htmlFor={id} {...props}>{props.children}</LinkButton>
		<input id={id} type="file" webkitdirectory="" directory="" key={key} multiple onChange={handleChange} tw="hidden!"/>
	</>

}

export default FolderInput

